<template>
  <v-container fluid>
    <v-card>
      <v-row no-gutters class="px-5 mb-5 pt-2 pb-3">
        <v-col cols="12" lg="2" sm="12" class="mr-5">
          <v-select
            v-model="search"
            :items="search_items"
            label="검색항목"
            item-text="text"
            item-value="value"
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" lg="2" sm="12">
          <v-text-field
            v-model="keyword"
            @keyup.enter="getData()"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          lg="1"
          sm="12"
          class="mt-2"
          style="white-space: nowrap"
        >
          <v-btn color="primary" @click="getData()" class="mr-2"> 검색 </v-btn>
          <v-btn @click="refresh()"> 초기화 </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :page.sync="page"
          :items-per-page="per_page"
          loading-text="로딩중.. 기다려주세요."
          no-data-text="데이터가 없습니다."
          elevation="0"
          hide-default-footer
          @click:row="detail($event)"
          style="cursor: pointer"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>클라이언트 리스트</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                hide-overlay
                transition="dialog-bottom-transition"
                max-width="700px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="primary white--text"
                    rounded
                    style="font-weight: bold"
                  >
                    클라이언트 등록
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dark color="primary">
                    <v-toolbar-title
                      >클라이언트 {{ formTitle }}</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-subheader>클라이언트</v-subheader>
                  <v-container class="pt-0">
                    <v-row no-gutters>
                      <v-text-field
                        v-model="editedItem.name"
                        label="클라이언트"
                        hide-details
                      ></v-text-field>
                    </v-row>
                  </v-container>
                  <v-divider></v-divider>
                  <v-subheader>계정정보</v-subheader>
                  <v-container class="pt-0">
                    <v-row no-gutters>
                      <v-text-field
                        v-model="editedItem.email"
                        :readonly="editedIndex > -1"
                        label="아이디"
                        hide-details
                      ></v-text-field>
                    </v-row>
                    <v-row no-gutters>
                      <v-text-field
                        v-model="editedItem.password"
                        :label="editedIndex > -1 ? '비밀번호 변경' : '비밀번호'"
                        hide-details
                      ></v-text-field>
                    </v-row>
                  </v-container>
                  <v-divider></v-divider>
                  <v-subheader>담당자</v-subheader>
                  <v-container class="pt-0">
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="editedItem.contact_name"
                          label="이름"
                          hide-details
                        ></v-text-field>
                        <v-text-field
                          v-model="editedItem.contact_email"
                          label="이메일"
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="editedItem.contact_phone"
                          label="연락처"
                          hide-details
                        ></v-text-field>
                        <v-text-field
                          v-model="editedItem.contact_url"
                          label="홈페이지"
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-card-actions class="justify-end">
                    <v-btn
                      :disabled="!valid"
                      color="blue darken-1 white--text"
                      @click="save()"
                      >{{ formTitle }}
                    </v-btn>
                    <v-btn color="primary white--text" @click="dialog = false"
                      >닫기
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <td @click.stop.prevent>
              <v-icon
                class="mr-1"
                color="green darken-1"
                @click="editItem(item)"
              >
                mdi-pencil-box-outline
              </v-icon>
            </td>
          </template>
        </v-data-table>
        <v-pagination
          v-model="page"
          :length="pageCount"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          color="primary"
          class="float-right mt-2"
          :total-visible="7"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ClientManage",
  data() {
    return {
      keyword: "",
      search: "name",
      search_items: [
        {
          value: "name",
          text: "클라이언트",
        },
        {
          value: "contact_name",
          text: "담당자",
        },
      ],

      headers: [
        {
          text: "클라이언트",
          value: "name",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "아이디",
          value: "email",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "담당자",
          value: "contact_name",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "연락처",
          value: "contact_phone",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "이메일",
          value: "contact_email",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "홈페이지",
          value: "contact_url",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "수정",
          value: "actions",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
          align: "center",
        },
      ],
      loading: false,
      items: [],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        id: null,
        name: null,
        email: null,
        password: null,
        contact_name: null,
        contact_phone: null,
        contact_email: null,
        contact_url: null,
      },
      defaultItem: {
        id: null,
        name: null,
        email: null,
        password: null,
        contact_name: null,
        contact_phone: null,
        contact_email: null,
        contact_url: null,
      },
      page: 1,
      per_page: 10,
      pageCount: 1,
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    page() {
      this.getData();
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "등록" : "수정";
    },
    valid() {
      if (this.editedIndex === -1) {
        if (
          this.editedItem.name &&
          this.editedItem.email &&
          this.editedItem.password
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.editedItem.name) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    refresh() {
      this.search = this.search_items[0].value;
      this.keyword = "";
      this.getData();
    },
    getData() {
      this.loading = true;
      let items = [];
      let url = `api/v1/admin/clients?page=${this.page}&per_page=${this.per_page}&${this.search}=${this.keyword}`;
      this.axios
        .get(url, {})
        .then((res) => {
          if (res.data.data.clients.length > 0) {
            res.data.data.clients.map((item) => {
              items.push({
                id: item.id,
                name: item.name,
                email: item.user.email,
                contact_name: item.contact_name,
                contact_phone: item.contact_phone,
                contact_email: item.contact_email,
                contact_url: item.contact_url,
              });
            });
          }
          this.items = items;
          this.loading = false;
          if (
            Number.isInteger(res.data.data.totalCount / this.per_page) === false
          ) {
            this.pageCount =
              Math.floor(res.data.data.totalCount / this.per_page) + 1;
          } else {
            this.pageCount = res.data.data.totalCount / this.per_page;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    detail(item) {
      this.$router.push(`/clients/detail/` + item.id);
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
        const data = {
          name: this.editedItem.name,
          contact_name: this.editedItem.contact_name,
          contact_phone: this.editedItem.contact_phone,
          contact_email: this.editedItem.contact_email,
          contact_url: this.editedItem.contact_url,
        };
        if (this.editedItem.password) {
          data["password"] = this.editedItem.password;
        }
        this.axios
          .put("api/v1/admin/clients/" + this.editedItem.id, data)
          .then(() => {
            this.getData();
            this.$store.commit("alert/showAlert", {
              message: `클라이언트가 수정되었습니다.`,
            });
          })
          .catch((err) => {
            this.$store.commit("alert/showAlert", {
              message: `${err.response.data.message}`,
            });
          });
      } else {
        this.items.push(this.editedItem);
        const data = {
          name: this.editedItem.name,
          email: this.editedItem.email,
          password: this.editedItem.password,
          contact_name: this.editedItem.contact_name,
          contact_phone: this.editedItem.contact_phone,
          contact_email: this.editedItem.contact_email,
          contact_url: this.editedItem.contact_url,
        };
        this.axios
          .post("api/v1/admin/clients", data)
          .then(() => {
            this.getData();
            this.$store.commit("alert/showAlert", {
              message: `클라이언트가 등록되었습니다.`,
            });
          })
          .catch((err) => {
            this.$store.commit("alert/showAlert", {
              message: `${err.response.data.message}`,
            });
          });
      }
      this.getData();
      this.close();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    // deleteItem(item) {
    //   this.editedIndex = this.items.indexOf(item);
    //   this.editedItem = Object.assign({}, item);
    //   this.$Swal
    //     .fire({
    //       text: "삭제 하시겠습니까?",
    //       showDenyButton: true,
    //       showCancelButton: false,
    //       confirmButtonText: "Yes",
    //       denyButtonText: `No`,
    //       confirmButtonColor: "#1D72DF",
    //       denyButtonColor: "#DC2B30",
    //       width: "400px",
    //     })
    //     .then((result) => {
    //       if (result.isConfirmed) {
    //         this.axios
    //           .delete("api/v1/admin/clients" + this.editedItem.id)
    //           .then(() => {
    //             this.getData();
    //           })
    //           .catch((err) => {
    //             console.error(err);
    //           });
    //       } else if (result.isDenied) {
    //         this.closeDelete();
    //       }
    //     });
    // },
  },
};
</script>
